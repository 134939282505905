.section-1 {
  z-index: 100;
  width: 100%;
  height: 100vh;
  background-color: #000;
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
}

.section-1 .slick-banner-list .back-img {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.section-1 .slick-banner-list .back-img.back-img-1 {
  background-image: url("main-banner-1.30c6eb0d.jpg");
}

.section-1 .slick-banner-list .back-img.back-img-2 {
  filter: saturate(.8);
  background-image: url("main-banner-2.7bc57092.jpg");
}

.section-1 .slick-banner-list .back-img.back-img-3 {
  background-image: url("main-banner-3.e30db3a3.jpg");
}

.section-1 .slick-banner-list .back-video {
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.section-1 .slick-banner-list .back-video > video {
  width: auto;
  min-height: 100vh;
}

.section-1 .slick-banner-list .slick-banner {
  z-index: 10;
  width: 100%;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.section-1 .slick-banner-list .slick-banner > span:nth-of-type(1) {
  color: #fff;
  margin-bottom: 2rem;
  font-size: 2.8rem;
  font-weight: 600;
}

.section-1 .slick-banner-list .slick-banner > span:nth-of-type(2) {
  color: #fff;
  opacity: .8;
  font-size: 1.3rem;
  font-weight: 300;
}

.section-1 .slick-banner-list .slick-banner.banner-1 > span:nth-of-type(1) {
  text-shadow: .5px .5px #333;
}

.section-1 .slick-banner-list .slick-banner.banner-1 > span:nth-of-type(2) {
  text-shadow: 1px 1px #222;
}

.section-1 .slideDots {
  opacity: 1;
  z-index: 100;
  width: 100%;
  justify-content: center;
  transition: opacity 1s;
  display: flex;
  position: absolute;
  bottom: 20vh;
}

.section-1 .slideDots > button {
  color: #fff;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 1.5rem;
  position: relative;
}

.section-1 .slideDots > button .circle_progress {
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(-90deg);
}

.section-1 .slideDots > button .circle_progress .frame {
  stroke: #fff3;
  opacity: 0;
  fill: none;
  transition: opacity .5s;
}

.section-1 .slideDots > button .circle_progress .bar {
  stroke: #fff;
  stroke-linecap: round;
  stroke-dasharray: 188.4;
  stroke-dashoffset: 188.4px;
  fill: none;
}

.section-1 .slideDots > button.on .circle_progress .frame {
  opacity: 1;
}

.section-1 .slideDots > button.on .circle_progress .bar {
  animation: 6s linear dashOffset;
}

.section-1 .mouse-scroll {
  z-index: 100;
  width: 100%;
  flex-direction: column;
  align-items: center;
  animation: 1.5s ease-in-out infinite bounce;
  display: flex;
  position: absolute;
  bottom: 5vh;
}

.section-1 .mouse-scroll > i {
  color: #fff;
  font-size: 1.5rem;
}

.section-1 .mouse-scroll > span {
  color: #fff;
  font-size: .8rem;
}

.section-2 {
  margin-top: 20rem;
}

.section-2 .business-content {
  width: 100%;
  align-items: center;
  margin-bottom: 20rem;
  display: flex;
  position: relative;
}

.section-2 .business-content:last-of-type {
  margin-bottom: 0;
}

.section-2 .business-content .no {
  color: #2478d7;
  margin-bottom: 2rem;
  font-size: 1rem;
}

.section-2 .business-content .title {
  color: #fff;
  margin-bottom: 2rem;
  font-size: 2.8rem;
  font-weight: 600;
}

.section-2 .business-content .desc {
  color: #fffc;
  margin-bottom: 3rem;
  font-size: 1.5rem;
  font-weight: 300;
}

.section-2 .business-content .detail-btn-cont .detail-btn > a {
  letter-spacing: 1px;
  color: #fff;
  height: 50px;
  width: 144px;
  align-items: center;
  padding-left: 26px;
  font-size: 1rem;
  font-weight: 300;
  transition: all .5s;
  display: flex;
  position: relative;
}

.section-2 .business-content .detail-btn-cont .detail-btn > a > span {
  z-index: 1;
  position: relative;
}

.section-2 .business-content .detail-btn-cont .detail-btn > a:hover {
  padding-left: 0;
}

.section-2 .business-content .detail-btn-cont .detail-btn > a:hover:before {
  background-color: #fac001;
  background-image: url("more_arr.afa22408.png");
  left: calc(100% - 50px);
}

.section-2 .business-content .detail-btn-cont .detail-btn > a:before {
  content: "";
  width: 50px;
  height: 50px;
  background-color: #00386a;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  transition: all .5s;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.section-2 .business-content .img-cont {
  position: relative;
}

.section-2 .business-content .img-cont .img {
  width: 100%;
  border-radius: 20px;
  overflow: hidden;
}

.section-2 .business-content .img-cont .img > img {
  width: 100%;
}

.section-2 .business-content .img-cont .icon {
  z-index: 1;
  max-width: 100px;
  background-color: #fff;
  border-radius: 20px;
  padding: 15px;
  position: absolute;
  bottom: 25px;
  left: -25px;
}

.section-2 .business-content .img-cont .icon > img {
  width: 100%;
}

.section-2 .business-content .right-content {
  padding-left: 70px;
}

.section-2 .slide-text {
  width: 100%;
  position: fixed;
  top: 200px;
}

.section-2 .slide-text p {
  color: #fff;
  opacity: .05;
  font-size: 9.375vw;
  font-weight: 700;
  line-height: 1;
}

.section-2 .slide-text p.right {
  text-align: right;
}

@keyframes dashOffset {
  0% {
    stroke-dashoffset: 188.4px;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes bounce {
  0%, 100% {
    transform: translateY(0);
  }

  30% {
    transform: translateY(-20px);
  }
}

@media (max-width: 768px) {
  .section-1 .slick-banner-list .back-img {
    background-size: auto 100%;
  }

  .section-1 .slick-banner-list .back-video {
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  .section-1 .slick-banner-list .back-video > video {
    width: auto;
    height: 100vh;
  }

  .section-1 .slick-banner-list .slick-banner > span:nth-of-type(1) {
    font-size: 2.2rem;
  }

  .section-1 .slick-banner-list .slick-banner > span:nth-of-type(2) {
    font-weight: 400;
  }

  .section-1 .mouse-scroll > i {
    font-size: 2rem;
  }

  .section-1 .mouse-scroll > span {
    font-size: 1.2rem;
  }

  .section-2 {
    margin-top: 15rem;
  }

  .section-2 .business-content .detail-btn-cont .detail-btn > a {
    font-size: 1.2rem;
  }

  .section-2 .business-content .img-cont .icon {
    max-width: 80px;
    bottom: -50px;
    left: auto;
    right: 25px;
  }

  .section-2 .business-content .no {
    font-size: 1.3rem;
  }
}

/*# sourceMappingURL=index.1948e467.css.map */
