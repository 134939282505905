.section-1 {
  background-color: #000;
  position: relative;
  overflow: hidden;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;

  .slick-banner-list {
    .back-img {
      width: 100%;
      height: 100vh;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      background-position: center center;

      &.back-img-1 {
        background-image: url("/src/assets/img/index/main-banner-1.jpg");
      }
      &.back-img-2 {
        background-image: url("/src/assets/img/index/main-banner-2.jpg");
        filter: saturate(0.8);
      }
      &.back-img-3 {
        background-image: url("/src/assets/img/index/main-banner-3.jpg");
      }
    }

    .back-video {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100vh;

      > video {
        width: auto;
        min-height: 100vh;
      }
    }

    .slick-banner {
      top: 50%;
      left: 50%;
      z-index: 10;
      transform: translate(-50%, -50%);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;

      > span:nth-of-type(1) {
        font-size: 2.8rem;
        color: #fff;
        margin-bottom: 2rem;
        font-weight: 600;
      }

      > span:nth-of-type(2) {
        font-size: 1.3rem;
        color: #fff;
        font-weight: 300;
        opacity: 0.8;
      }

      &.banner-1 {
        > span:nth-of-type(1) {
          text-shadow: 0.5px 0.5px 0 #333;
        }
        > span:nth-of-type(2) {
          text-shadow: 1px 1px 0 #222;
        }
      }
      &.banner-2 {
        > span:nth-of-type(1) {
        }
        > span:nth-of-type(2) {
        }
      }
      &.banner-3 {
        > span:nth-of-type(1) {
        }
        > span:nth-of-type(2) {
        }
      }
    }
  }

  .slideDots {
    position: absolute;
    opacity: 1;
    transition: opacity 1s;
    z-index: 100;
    width: 100%;
    bottom: 20vh;
    display: flex;
    justify-content: center;

    > button {
      font-size: 1.5rem;
      color: #fff;
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 50%;

      .circle_progress {
        transform: rotate(-90deg);
        position: absolute;
        left: 0;
        top: 0;

        .frame {
          stroke: rgba(255, 255, 255, 0.2);
          opacity: 0;
          transition: opacity 0.5s;
          fill: none;
        }

        .bar {
          stroke: #fff;
          stroke-linecap: round;
          stroke-dasharray: 188.4;
          stroke-dashoffset: 188.4;
          fill: none;
        }
      }

      &.on {
        .circle_progress {
          .frame {
            opacity: 1;
          }
          .bar {
            animation: dashOffset 6s linear;
          }
        }
      }
    }
  }

  .mouse-scroll {
    position: absolute;
    z-index: 100;
    width: 100%;
    bottom: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: bounce 1.5s infinite ease-in-out;

    > i {
      font-size: 1.5rem;
      color: #fff;
    }

    > span {
      font-size: 0.8rem;
      color: #fff;
    }
  }
}

.section-2 {
  margin-top: 20rem;

  .business-content {
    margin-bottom: 20rem;
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }

    .no {
      font-size: 1rem;
      color: #2478d7;
      margin-bottom: 2rem;
    }

    .title {
      font-size: 2.8rem;
      color: #fff;
      margin-bottom: 2rem;
      font-weight: 600;
    }

    .desc {
      font-size: 1.5rem;
      color: rgb(255 255 255 / 80%);
      font-weight: 300;
      margin-bottom: 3rem;
    }

    .detail-btn-cont {
      .detail-btn {
        > a {
          letter-spacing: 1px;
          color: #fff;
          font-size: 1rem;
          font-weight: 300;
          display: flex;
          align-items: center;
          height: 50px;
          padding-left: 26px;
          position: relative;
          width: 144px;
          transition: all 0.5s;

          > span {
            position: relative;
            z-index: 1;
          }

          &:hover {
            padding-left: 0;

            &::before {
              background-image: url("/src/assets/img/icon/more_arr.png");
              left: calc(100% - 50px);
              background-color: #fac001;
            }
          }

          &::before {
            content: "";
            transition: all 0.5s;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: #00386a;
            background-repeat: no-repeat;
            background-position: center center;
            position: absolute;
            left: 0;
            top: 0;
            display: block;
            border-radius: 50%;
          }
        }
      }
    }

    .img-cont {
      position: relative;

      .img {
        width: 100%;
        border-radius: 20px;
        overflow: hidden;

        > img {
          width: 100%;
        }
      }

      .icon {
        position: absolute;
        bottom: 25px;
        left: -25px;
        z-index: 1;
        background-color: #fff;
        border-radius: 20px;
        max-width: 100px;
        padding: 15px;

        > img {
          width: 100%;
        }
      }
    }

    .right-content {
      padding-left: 70px;
    }
  }

  .slide-text {
    position: fixed;
    width: 100%;
    top: 200px;

    p {
      line-height: 1;
      font-size: 9.375vw;
      font-weight: 700;
      color: #fff;
      opacity: 0.05;

      &.right {
        text-align: right;
      }
    }
  }
}

@keyframes dashOffset {
  0% {
    stroke-dashoffset: 188.4;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-20px);
  }
}

@media (max-width: 768px) {
  .section-1 {
    .slick-banner-list {
      .back-img {
        background-size: auto 100%;
      }

      .back-video {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;

        > video {
          width: auto;
          height: 100vh;
        }
      }

      .slick-banner {
        > span:nth-of-type(1) {
          font-size: 2.2rem;
        }

        > span:nth-of-type(2) {
          font-weight: 400;
        }
      }
    }

    .mouse-scroll {
      > i {
        font-size: 2rem;
      }

      > span {
        font-size: 1.2rem;
      }
    }
  }

  .section-2 {
    margin-top: 15rem;

    .business-content {
      .detail-btn-cont {
        .detail-btn {
          > a {
            font-size: 1.2rem;
          }
        }
      }

      .img-cont {
  
        .icon {
          bottom: -50px;
          left: auto;
          right: 25px;
          max-width: 80px;
        }
      }

      .no {
        font-size: 1.3rem;
      }
    }
  }
}
